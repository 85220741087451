@import url(./fonts/fonts.css);
/*
--secondary: #fc5800;
*/
:root {
	--dark: #272727;
	--primary: #ff00a2;
	--primarydarker: #92005d;
	--primarytransparent: #ff00a22a;
	--secondary: #006381;
	--secondarytransparent: #0063812a;
	--radius: .25rem;

	--backgroundwindow: white;
	--colortext: white;

	--margin: 2rem;
	--spacing: 1rem;

	--shadow: #00000071 0 0 2rem;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'barlow', sans-serif;
	overflow:hidden;
}

.background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: linear-gradient(-45deg, var(--primary), var(--secondary));
}