.text-body {
	padding: var(--margin);
}
.text {
	font-family: monospace;
	margin: .5rem 0;
	background-color: #f0ffe9;;
}

.text--skriked {
	text-decoration: line-through;
}

.text--CRLF {
	margin-top: 3rem;
}