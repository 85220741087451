.login__root {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.login__systemname {
	position: absolute;
	bottom: 0;
	right: 1rem;
	color: white;
	font-size: .75rem;
}

@keyframes shake {
	10%, 90% {
		transform: translate3d(-2px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(4px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-8px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(8px, 0, 0);
	}
}

.login__container--error {
	animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

.login__container {
	padding: var(--margin);
	background-color: var(--backgroundwindow);
	display: flex;
	align-items:flex-start;
	border-radius: var(--radius);
	box-shadow: var(--shadow);
}

.login__image {
	width: 5rem;
	height: auto;
	border-radius: 100%;
	border: .5rem solid var(--primarytransparent);
	margin-right: var(--spacing);
}

.login__input {
	display: block;
	margin-bottom: var(--spacing);
	padding: .5rem;
	border: 1px solid var(--primarytransparent);
	border-radius: var(--radius);
	font-family: 'barlow', sans-serif;
}

.login__message--error {
	border-radius: var(--radius);
	border: 1px solid var(--primary);
	background-color: var(--primarytransparent);
	padding: var(--spacing) calc(2 * var(--spacing)	);
	color: var(--primary)
}

.login__submit {
	border-radius: 2rem;
	background-color: var(--primary);
	border: none;
	padding: .5rem;
	color: var(--colortext);
	
	font-weight: 700;
	padding: var(--spacing) calc(2 * var(--spacing)	);
	font-family: 'barlow', sans-serif;
}

.login__submit:hover {
	cursor: pointer;
	opacity: .7;
}

.login__submit:active {
	opacity: 1;
	cursor: pointer;
}