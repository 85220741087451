.file {
	display: inline-block;
	margin: var(--spacing);
	vertical-align: top;
	width: 6rem;
}

.file:hover {
	cursor: pointer;
}

.file__icon {
	font-family: 'icons';
	font-size: 3rem;
	color: black;
	text-align: center;
}

.file__name {
	background-color: white;
	text-align: center;
	padding: .25rem;
	border-radius: .25rem;
	border: 1px dotted var(--primary);
	max-width: 5rem;
	display: block;
}