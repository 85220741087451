.desktop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: url(../../public/tuna2.jpg);
	background-position: center;
	background-size: 25%;
	display: flex;
	flex-direction: column;
}

.desktop-content {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.menubar {
	background-color: white;
	padding: .5rem 1rem;
	box-shadow: var(--shadow);
	border-radius: var(--radius);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.menubar__logo {
	border-radius: 100%;
	width: 1rem;
	height: 1rem;
	text-align: center;
	border: 1px solid grey
}

.menubar__logo:hover {
	cursor: pointer;
	background: var(--primary);
	color: white;
}

.date {
	margin: 0;
}

.date:hover {
	cursor: default;
}