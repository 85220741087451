@font-face {
    font-family: 'barlow';
    src: url('./Barlow-Regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'barlow';
    src: url('./Barlow-Bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
	font-family: 'icons';
	font-weight: 400;
	font-style: normal;
	src: url('./MaterialIconsOutlined-Regular.otf') format('opentype');
}

