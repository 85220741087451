@keyframes opening {
	0% {
		opacity: 0;
	}
	50% {
		opacity: .5;
	}
	100% {
		opacity: 1;
	}
}

.window {
	animation: opening .2s steps(1);
	background-color: white;
	position: absolute;
	top: 1rem;
	left: 1rem;
	min-width: 30vw;
	max-width: 90vw;
	border-radius: var(--radius);
	border: 1px solid var(--secondary);
	box-shadow: var(--shadow);
	height: 75%;
	display: flex;
	flex-direction: column;
}

.window__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: .5rem;
	border-bottom: 1px solid black;
	font-weight: 700;
}

.window__body {
	height: 100%;
	width: 100%;
	overflow-y: scroll;
}

.header__name {
	margin: 0;
}

.header__close {
	width: 1rem;
	height: 1rem;
	text-align: center;
	display: block;
	border-radius: 1rem;
	border: none;
	background: var(--primary);
	color: white;
	font-weight: 700;
	font-size: .6rem;
}

.header__close:hover {
	cursor: pointer;
	background: var(--primarydarker);
}

.window--moving:hover {
	cursor: grabbing
}

.window--notmoving:hover {
	cursor: grab
}