.image__body {
	background-color: var(--dark);
	height: 100%;
	width: 100%;
	position: relative;
}

.image__img {
	max-width: 100%;
	max-height: 100%;
	height: auto;
	width: auto;
	display: block;
	margin: auto;
	position: absolute;
	bottom: -100%;
	top: -100%;
	left: 0%;
	right: 0%;
	z-index: 2;
}